import { ClientConfigService, configData } from './../shared/services/client-config.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  clientConfigData: any;

  constructor(
    private _translate: TranslateService,
    private titleService: Title,
    private apiService: ClientConfigService
  ) {

    _translate.setDefaultLang('en');
    _translate.addLangs(['en', 'fr']);
  }

  ngOnInit() {

    //client config

    configData.subscribe(
      (clientConfigData: any) => {
        this.clientConfigData = clientConfigData
        this.titleService.setTitle(clientConfigData.name)
        this.favIcon.href = clientConfigData.logo;
      })

    const selectLang = localStorage.getItem('selectLang');

    if (selectLang) {
      this._translate.use(selectLang);
    } else {
      this._translate.use(this._translate.getDefaultLang());
    }
  }
}
