export enum localStorageKey {
  ACCESS_TOKEN = '$LoopBack$accessTokenId',
  CURRENT_USER = '$LoopBack$currentUserData',
  REMEMBER_ME = '$LoopBack$rememberMe',
  LANG_KEY = 'NG_TRANSLATE_LANG_KEY'
}

export const enum EUserRoles {
  ADMIN = 'admin pro',
  USER = 'broker'
}

export interface IUserApiLoginResponse {
  id?: string;
  ttl?: number;
  created?: string;
  userId?: string;
  error?: IApiFailed;
}

export interface IUserApiLogin {
  username: string;
  password: string;
}

export interface IApiFailed {
  statusCode: number;
  name: string;
  message: string;
  code: string;
  status: string;
  stack: string;
}

export interface ISession {
  id: string;
  userId: number;
}

export interface IUserApiLoginSuccess extends ISession {
  ttl: number;
  created: string;
  user: IUserDto;
}

export interface IUserDto {
  currentSignInAt: string;
  currentSignInIp: string;
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  firebaseToken: string;
  id: number;
  lastSignInAt: string;
  lastSignInIp: string;
  medicusCode: string;
  realm: string;
  rememberCreatedAt: boolean;
  signInCount: number;
  type: EUserRoles;
  username: string;
}
