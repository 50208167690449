import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuard } from './auth.guard';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private _authGuard: AuthGuard) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._authGuard.token;

    let duplicate = request.clone();

    if (token) {
      duplicate = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      });
    }

    return next.handle(duplicate);
  }
}
