import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EUserRoles, IUserDto, localStorageKey } from '@app/navShell/nav.model';


export interface IUser {
  userType: EUserRoles;
  userName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router) {
    this.token = localStorage.getItem(localStorageKey.ACCESS_TOKEN);
    this.user = JSON.parse(localStorage.getItem(localStorageKey.CURRENT_USER));
  }

  private _token: string = null;
  private _user: IUser = null;

  set token(value: string) {
    if (value) {
      this._token = value;
      localStorage.setItem(localStorageKey.ACCESS_TOKEN, value);
    } else {
      this._token = null;
      localStorage.removeItem(localStorageKey.ACCESS_TOKEN);
    }
  }

  get token(): string {
    return this._token;
  }

  set user(value: IUserDto) {
    if (value) {
      this._user = {
        userType: value.type,
        userName: value.username,
      };

      localStorage.setItem(localStorageKey.CURRENT_USER, JSON.stringify(value));
    } else {
      this._user = null;
      localStorage.removeItem(localStorageKey.CURRENT_USER);
    }
  }

  get currentUser(): IUser {
    return this._user;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this._token && this._user && (this._user.userType.toUpperCase() === EUserRoles.ADMIN.toUpperCase())) {
      
      if (state.url === '/') {
        
        this._router.navigate(['/b2c/users']);
      }

      return true;
    }

    if (this._token && this._user && (this._user.userType.toUpperCase() === EUserRoles.USER.toUpperCase())) {
      const editorUrl = /\/prospects\/editor/;

      if (!editorUrl.test(state.url)) {
        this._router.navigate(['/b2c/prospects/editor']);
      }

      return true;
    }

    if (state.url === '/') {
      return true;
    }

    this._router.navigate(['/']);

    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }

  cleanState() {
    this.token = null;
    this.user = null;
  }
}
