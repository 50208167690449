import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ClientConfigService extends BaseService {

  constructor(private http: HttpClient) {
    super()

    this.getConfigData().subscribe((data: any) => {
      configData.next(data.response)
    })
  }

  public getConfigData() {
    return this.http.get(this.url(`/Admins/getServers`))
  }
}

export var configData = new BehaviorSubject(<any>{});
