import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TRANSLATE } from '../functions/translate.helper';

@Injectable({
  providedIn: 'root'
})
export class VocabularyService {
  constructor() { }

  // Main toolbar
  // toolbarAdminAppName = environment.name //TRANSLATE('main.toolbar.adminAppName');
  toolbarUserAppName = TRANSLATE('main.toolbar.userAppName');
  toolbarUserGoToDashboard = TRANSLATE('main.toolbar.goToDashboard');
  toolbarNewProspect = TRANSLATE('main.toolbar.newProspect');
  toolbarOnAdminMode = TRANSLATE('main.toolbar.onAdminMode');
  toolbarLogout = TRANSLATE('main.toolbar.logout');
  toolbarLangFr = TRANSLATE('main.toolbar.langFr');
  toolbarLangEn = TRANSLATE('main.toolbar.langEn');

  // Main saidnav
  sidenavUsers = TRANSLATE('main.saidnav.users');
  sidenavProspects = TRANSLATE('main.saidnav.prospects');
  sidenavDocumentation = TRANSLATE('main.saidnav.documentation');
  sidenavGuarentees = TRANSLATE('main.saidnav.guarentees');
  sidenavPlanManagement = TRANSLATE('main.saidnav.planManagement');
  sidenavPriceUpdater = TRANSLATE('main.saidnav.priceUpdater');
  sidenavCompanies = TRANSLATE('main.saidnav.companies');
  sidenavMobileUsers = TRANSLATE('main.saidnav.mobileUsers');

  // addUserForm
  formAddUserUsername = TRANSLATE('form.addUser.username');

  // Guarentees page
  guaranteesTitle = TRANSLATE('views.guarantees.guarantees');
  guaranteesName = TRANSLATE('views.guarantees.name');
  guaranteesDescriptionEnglish = TRANSLATE(
    'views.guarantees.description_english'
  );
  guaranteesDescriptionFrench = TRANSLATE(
    'views.guarantees.description_french'
  );
  guaranteesSearchPlansAndMedicalOptions = TRANSLATE(
    'views.guarantees.search_plans_and_medical_options'
  );
  guaranteesChooseTheCompanyYouWantToUpdate = TRANSLATE(
    'views.guarantees.choose_the_company_you_want_to_update'
  );
  guaranteesChooseThePlanYouWantToUpdate = TRANSLATE(
    'views.guarantees.choose_the_plan_you_want_to_update'
  );
  guaranteesCompany = TRANSLATE('views.guarantees.company');
  guaranteesPlan = TRANSLATE('views.guarantees.plan');
  guaranteesUploadCsv = TRANSLATE('views.guarantees.upload_csv');
  guaranteesUploadExcel = TRANSLATE('views.guarantees.upload_excel');
  guaranteesChooseFiles = TRANSLATE('views.guarantees.choose_files');
  guaranteesNoFileChosen = TRANSLATE('views.guarantees.no_file_chosen');
  guaranteesSuccess = TRANSLATE('views.guarantees.success');
  guaranteesPleaseSelectACsvFileFirst = TRANSLATE(
    'views.guarantees.please_select_a_csv_file_first'
  );
  guaranteesSaveChanges = TRANSLATE('views.guarantees.save_changes');
  guaranteesTooManyLinesInTheCSVFile = TRANSLATE(
    'views.guarantees.too_many_lines_in_the_CSV_file'
  );
  guaranteesNewGuaranteesUploaded = TRANSLATE(
    'views.guarantees.new_guarantees_uploaded'
  );
  guaranteesFailedToUploadGuarantees = TRANSLATE(
    'views.guarantees.failed_to_upload_guarantees'
  );
  guaranteesCsvFileDoesNotHaveTheRightHeaders = TRANSLATE(
    'views.guarantees.csv_file_does_not_have_the_right_headers'
  );
  guaranteesGuaranteesSuccessfullyUpdatedSuccess = TRANSLATE(
    'views.guarantees.guarantees_successfully_updated_success'
  );
  guaranteesCouldNotUpdateGuarantee = TRANSLATE(
    'views.guarantees.could_not_update_guarantee'
  );
  guaranteesDownloadExcel = TRANSLATE('views.guarantees.download_excel');

  // Priceupdate page
  priceUpdaterPriceUpdater = TRANSLATE('views.price_updater.price_updater');
  priceUpdaterSearchPlansAndItsPrices = TRANSLATE(
    'views.price_updater.search_plans_and_its_prices'
  );
  priceUpdaterChooseTheCompanyYouWantToUpdate = TRANSLATE(
    'views.price_updater.choose_the_company_you_want_to_update'
  );
  priceUpdaterCompany = TRANSLATE('views.price_updater.company');
  priceUpdaterChooseThePlanYouWantToUpdate = TRANSLATE(
    'views.price_updater.choose_the_plan_you_want_to_update'
  );
  priceUpdaterPlan = TRANSLATE('views.price_updater.plan');
  priceUpdaterCurrency = TRANSLATE('views.price_updater.currency');
  priceUpdaterUploadCsv = TRANSLATE('views.price_updater.upload_csv');
  priceUpdaterChooseFiles = TRANSLATE('views.price_updater.choose_files');
  priceUpdaterNoFileChosen = TRANSLATE('views.price_updater.no_file_chosen');
  priceUpdaterPrice = TRANSLATE('views.price_updater.price');
  priceUpdaterStartAge = TRANSLATE('views.price_updater.start_age');
  priceUpdaterEndAge = TRANSLATE('views.price_updater.end_age');
  priceUpdaterExcess = TRANSLATE('views.price_updater.excess');
  priceUpdaterFranchise = TRANSLATE('views.price_updater.franchise');
  priceUpdaterDeductible = TRANSLATE('views.price_updater.deductible');
  priceUpdaterFamily = TRANSLATE('views.price_updater.family');
  priceUpdaterDestroy = TRANSLATE('views.price_updater.destroy');
  priceUpdaterOf = TRANSLATE('views.price_updater.of');
  priceUpdaterAddRow = TRANSLATE('views.price_updater.add_row');
  priceUpdaterSaveChanges = TRANSLATE('views.price_updater.save_changes');
  priceUpdaterPricesAdded = TRANSLATE('views.price_updater.prices_added');
  priceUpdaterPriceListUpdatedForPlan = TRANSLATE(
    'views.price_updater.price_list_updated_for_plan'
  );
  priceUpdaterSuccessPriceDeleted = TRANSLATE(
    'views.price_updater.success_price_deleted'
  );
  priceUpdaterPricesUpdatedSuccessfully = TRANSLATE(
    'views.price_updater.prices_updated_successfully'
  );
  priceUpdaterErrorUploadingCsvPrices = TRANSLATE(
    'views.price_updater.error_uploading_csv_prices'
  );
  priceUpdaterErrorWhileRemovingPrices = TRANSLATE(
    'views.price_updater.error_while_removing_prices'
  );
  priceUpdaterFamilyFieldMustBe = TRANSLATE(
    'views.price_updater.family_field_must_be'
  );
  priceUpdaterCsvFileNotRightHeaders = TRANSLATE(
    'views.price_updater.csv_file_not_right_headers'
  );
  priceUpdaterPleaseSelectACVS = TRANSLATE(
    'views.price_updater.please_select_a_CVS'
  );
  priceUpdaterPriceSuccesfullyDeleted = TRANSLATE(
    'views.price_updater.price_succesfully_deleted'
  );
  priceUpdaterPriceNotDeleted = TRANSLATE(
    'views.price_updater.price_not_deleted'
  );
  priceUpdaterExportCsv = TRANSLATE('views.price_updater.export_csv');
  priceUpdaterAnError = TRANSLATE('views.price_updater.an_error');
  priceUpdaterRowAdded = TRANSLATE('views.price_updater.row_added');

  // Snackbars messages
  errorEmailNotFound = TRANSLATE('snack_bars.search.emailNotFound');
  userDeleted = TRANSLATE('snack_bars.users.user_deleted');
  userAdded = TRANSLATE('snack_bars.users.user_added');
  prospectDeleted = TRANSLATE('snack_bars.prospects.prospect_deleted');
  userUpdated = TRANSLATE('snack_bars.users.user_updated');
  passwordUpdated = TRANSLATE('snack_bars.users.password_updated');
  guaranteesUpdated = TRANSLATE('snack_bars.guarantees.list_updated');
  planDeleted = TRANSLATE('snack_bars.plan_management.plan_deleted');
  planAdded = TRANSLATE('snack_bars.plan_management.plan_added');
  planUpdated = TRANSLATE('snack_bars.plan_management.plan_updated');
  listUpdated = TRANSLATE('snack_bars.documentations.list_updated');
  documentDeleted = TRANSLATE('snack_bars.documentations.document_deleted');
  residencesUpdated = TRANSLATE(
    'snack_bars.plan_management.residences_updated'
  );
  planEnable = TRANSLATE('snack_bars.plan_management.plan_enable');
  planDisable = TRANSLATE('snack_bars.plan_management.plan_disable');
  priceDelete = TRANSLATE('snack_bars.price_updater.price_delete');
  addCompany = TRANSLATE('snack_bars.companies.add_company');

  // New prospect page
  prospectSearchPlaceholder = TRANSLATE('views.prospect.search_placeholder');
  prospectSearchButton = TRANSLATE('views.prospect.search_button');
  prospectHeaderOne = TRANSLATE('views.prospect.header1');
  prospectHeaderTwo = TRANSLATE('views.prospect.header2');
  prospectDifferentPlansHeader = TRANSLATE('views.prospect.different_plans_header');
  prospectButton = TRANSLATE('views.prospect.button');
  prospectDownloadHeader = TRANSLATE('views.prospect.download_header');
  prospectProfileUpdated = TRANSLATE('views.prospect.profile_updated');
  prospectInsuranceUpdated = TRANSLATE('views.prospect.insurance_updated');
  prospectGettingPlans = TRANSLATE('views.prospect.getting_plans');
  prospectInsurancePlanInformation = TRANSLATE(
    'views.prospect.insurance_plan_information'
  );

  templateTitle = TRANSLATE('views.template.title');
  templateCreate = TRANSLATE('views.template.create');
  templateSave = TRANSLATE('views.template.save');
  templateLanguage = TRANSLATE('views.template.language');
  templateSuccess = TRANSLATE('views.template.success');
  templateSuccessUpdated = TRANSLATE('views.template.success_updated');
  templateSuccessDeleted = TRANSLATE('views.template.success_deleted');
  templateEdit = TRANSLATE('views.template.edit');
  templateClearText = TRANSLATE('views.template.clear_text');
  templateDelete = TRANSLATE('views.template.delete');

  prospectQuoteRequest = TRANSLATE('views.prospects.quote_request');
  prospectsDestroy = TRANSLATE('views.prospects.destroy');
  prospectsAllProspects = TRANSLATE('views.prospects.all_prospects');
  prospectsFirstName = TRANSLATE('views.prospects.first_name');
  prospectsLastName = TRANSLATE('views.prospects.last_name');
  prospectsCreatedBy = TRANSLATE('views.prospects.created_by');
  prospectsCreatedAt = TRANSLATE('views.prospects.created_at');
  prospectsSearchUser = TRANSLATE('views.prospects.search_user');
  prospectsFirstNameLike = TRANSLATE('views.prospects.first_name_like');
  prospectsLastNameLike = TRANSLATE('views.prospects.last_name_like');
  prospectsEmailLike = TRANSLATE('views.prospects.email_like');
  prospectsReset = TRANSLATE('views.prospects.reset');
  prospectsSubmit = TRANSLATE('views.prospects.submit');
  prospectsProspectDetails = TRANSLATE('views.prospects.prospect_details');
  prospectsProspectName = TRANSLATE('views.prospects.prospect_name');
  prospectsProspectEmail = TRANSLATE('views.prospects.prospect_email');
  prospectsProspectDateOfBirth = TRANSLATE(
    'views.prospects.prospect_date_of_birth'
  );
  prospectsNationality = TRANSLATE('views.prospects.nationality');
  prospectsProspectPhone = TRANSLATE('views.prospects.prospect_phone');
  prospectsProspectSkypeId = TRANSLATE('views.prospects.prospect_skype_id');
  prospectsPartnerName = TRANSLATE('views.prospects.partner_name');
  prospectsPartnerDateOfBirth = TRANSLATE(
    'views.prospects.partner_date_of_birth'
  );
  prospectsPartnerEmail = TRANSLATE('views.prospects.partner_email');
  prospectsPartnerNationality = TRANSLATE(
    'views.prospects.partner_nationality'
  );
  prospectsPartnerPhone = TRANSLATE('views.prospects.partner_phone');
  prospectsPartnerSkypeId = TRANSLATE('views.prospects.partner_skype_id');
  prospectsCountryOfResidence = TRANSLATE(
    'views.prospects.country_of_residence'
  );
  prospectsCurrency = TRANSLATE('views.prospects.currency');
  prospectsStartingDate = TRANSLATE('views.prospects.starting_date');
  prospectsPeriodOfCoverage = TRANSLATE('views.prospects.period_of_coverage');
  prospectsOtherSpecificInquiries = TRANSLATE(
    'views.prospects.other_specific_inquiries'
  );
  specification = TRANSLATE('specification');
  prospectsSuccessProspectDeleted = TRANSLATE(
    'views.prospects.success_prospect_deleted'
  );
  prospectsOf = TRANSLATE('views.prospects.of');
  prospectsMore = TRANSLATE('views.prospects.more');
  prospectsErrorDeleting = TRANSLATE('views.prospects.error_deleting');
  prospectsCreatedByLike = TRANSLATE('views.prospects.created_by_like');

  // OTHER
  comparisonTitle = TRANSLATE('comparison_title');
  parther = TRANSLATE('partner');
  profileInsured = TRANSLATE('profile_insured');
  insuranceDetails = TRANSLATE('insurance_details');
  areYouSure = TRANSLATE('are_you_sure');
  discountType = TRANSLATE('discount_type');
  premiumRate = TRANSLATE('premium_rate');
  downloadDocumentation = TRANSLATE('download_documentation');
  yes = TRANSLATE('y');
  no = TRANSLATE('n');
  company = TRANSLATE('company');
  fieldRequired = TRANSLATE('field_required');
  validateEmail = TRANSLATE('validate_email');
  mainSpecification = TRANSLATE('main_specification');
  pdfTitle = TRANSLATE('pdfTitle');
  edit = TRANSLATE('edit');
  send = TRANSLATE('send');
  sendButton = TRANSLATE('send_button');
  backToListOfPlans = TRANSLATE('back_to_list_of_plans');
  action = TRANSLATE('action');
  downloadButton = TRANSLATE('download_button');
  noMatchingEntriesFound = TRANSLATE('no_matching_entries_found');

  all = TRANSLATE('all');
  email = TRANSLATE('email');
  civility = TRANSLATE('civility');
  country = TRANSLATE('country');
  prospectInsuranceInfo = TRANSLATE('views.prospect_insurance.info');
  prospectInsuranceButton = TRANSLATE('views.prospect_insurance.button');
  countryOfResidence = TRANSLATE('country_of_residence');
  contact = TRANSLATE('contact');
  childrenNumber = TRANSLATE('children_number');
  children = TRANSLATE('children');
  title = TRANSLATE('title');
  mr = TRANSLATE('mr');
  ms = TRANSLATE('ms');
  mrs = TRANSLATE('mrs');
  name = TRANSLATE('name');
  code = TRANSLATE('code');
  firstName = TRANSLATE('first_name');
  lastName = TRANSLATE('last_name');
  of = TRANSLATE('of');
  dob = TRANSLATE('dob');
  pdfDob = TRANSLATE('pdfDob');
  nationality = TRANSLATE('nationality');
  cfe = TRANSLATE('cfe');
  partnerToBeInsured = TRANSLATE('partner_to_be_insured');
  childrenToBeInsured = TRANSLATE('children_to_be_insured');
  loginTitle = TRANSLATE('loginPage.title');
  phone = TRANSLATE('phone');
  skypeId = TRANSLATE('skype_id');

  ago = TRANSLATE('ago');
  justNow = TRANSLATE('just_now');
  year = TRANSLATE('year');
  month = TRANSLATE('month');
  day = TRANSLATE('day');
  hour = TRANSLATE('hour');
  minute = TRANSLATE('minute');
  second = TRANSLATE('second');

  years = TRANSLATE('years');
  months = TRANSLATE('months');
  days = TRANSLATE('days');
  hours = TRANSLATE('hours');
  minutes = TRANSLATE('minutes');
  seconds = TRANSLATE('seconds');

  loginBtn = TRANSLATE('loginPage.btn');
  loginFailed = TRANSLATE('loginPage.errors.login');
  loginFailedName = TRANSLATE('loginPage.errors.name');
  loginFailedPassword = TRANSLATE('loginPage.errors.password');
  formPlchldrLogin = TRANSLATE('forms.placeholders.login');
  formPlchldrPassword = TRANSLATE('forms.placeholders.password');
  formLabelsRowsPerPage = TRANSLATE('forms.labels.rows_per_page');
  saveCompleted = TRANSLATE('messages.saveCompleted');
  unauthorized = TRANSLATE('messages.unauthorized');
  usersAllUsers = TRANSLATE('views.users.all_users');
  usersUsername = TRANSLATE('views.users.username');
  usersEnable = TRANSLATE('views.users.enable');
  usersSelectEnableOption = TRANSLATE('views.users.select_enable_option');
  usersSelectUserType = TRANSLATE('views.users.select_user_type');
  usersUserType = TRANSLATE('views.users.user_type');
  usersDelete = TRANSLATE('views.users.delete');
  usersUpdatePassword = TRANSLATE('views.users.update_password');
  usersSubmit = TRANSLATE('views.users.submit');
  usersNewUser = TRANSLATE('views.users.new_user');
  usersOf = TRANSLATE('views.users.of');
  usersConfirmPassword = TRANSLATE('views.users.confirm_password');
  usersPassword = TRANSLATE('views.users.password');
  usersUpdate = TRANSLATE('views.users.update');
  usersChangeUserPassword = TRANSLATE('views.users.change_user_password');
  usersSuccessUserAdded = TRANSLATE('views.users.success_user_added');
  usersSuccessUserDeleted = TRANSLATE('views.users.success_user_deleted');
  usersAreYouSureYesNo = TRANSLATE('views.users.are_you_sure_yes_no');
  usersSearchUser = TRANSLATE('views.users.search_user');
  usersReset = TRANSLATE('views.users.reset');
  usersYes = TRANSLATE('views.users.yes');
  usersNo = TRANSLATE('views.users.no');
  usersEnabled = TRANSLATE('views.users.enabled');
  usersFilesUploaded = TRANSLATE('views.users.files_uploaded');
  usersUserUpdated = TRANSLATE('views.users.user_updated');
  usersUserNotUpdated = TRANSLATE('views.users.user_not_updated');
  usersPasswordsDoesntWork = TRANSLATE('views.users.passwords_doesnt_work');
  usersErrorDeletingUser = TRANSLATE('views.users.error_deleting_user');
  usersUser = TRANSLATE('views.users.user');
  usersUsers = TRANSLATE('views.users.users');
  usersAdmin = TRANSLATE('views.users.admin');
  usersErrorUserAdded = TRANSLATE('views.users.error_user_added');
  usersUnkownError = TRANSLATE('views.users.unkown_error');
  usersMobile = TRANSLATE('views.users.mobile');
  usersFirstName = TRANSLATE('views.users.first_name');
  usersLastName = TRANSLATE('views.users.last_name');
  usersMobileUsers = TRANSLATE('views.users.mobile_users');
  usersProvider = TRANSLATE('views.users.provider');
  usersPoints = TRANSLATE('views.users.points');
  usersRegisteredWith = TRANSLATE('views.users.registered_with');
  usersExportTxt = TRANSLATE('views.users.export_txt');

  documentationName = TRANSLATE('views.documentation.name');
  documentationPath = TRANSLATE('views.documentation.path');
  documentationLanguage = TRANSLATE('views.documentation.language');
  documentationUploadDocuments = TRANSLATE(
    'views.documentation.upload_documents'
  );
  documentationOf = TRANSLATE('views.documentation.of');
  documentationAreYouSureYesNo = TRANSLATE(
    'views.documentation.are_you_sure_yes_no'
  );
  documentationDestroy = TRANSLATE('views.documentation.destroy');
  documentationUploadCompanyDocuments = TRANSLATE(
    'views.documentation.upload_company_documents'
  );
  documentationCompanyDocuments = TRANSLATE(
    'views.documentation.company_documents'
  );
  documentationChooseFiles = TRANSLATE('views.documentation.choose_files');
  documentationNoFileChosen = TRANSLATE('views.documentation.no_file_chosen');
  documentationReset = TRANSLATE('views.documentation.reset');
  documentationSubmit = TRANSLATE('views.documentation.submit');
  documentationUpload = TRANSLATE('views.documentation.upload');
  documentationSize = TRANSLATE('views.documentation.size');
  documentationProgress = TRANSLATE('views.documentation.progress');
  documentationSelectCompanyAndLanguage = TRANSLATE(
    'views.documentation.select_company_and_language'
  );
  documentationCompany = TRANSLATE('views.documentation.company');
  documentationEnglish = TRANSLATE('views.documentation.english');
  documentationFrench = TRANSLATE('views.documentation.french');
  documentationDocumentNameUpdated = TRANSLATE(
    'views.documentation.document_name_updated'
  );
  documentationFailedToUpdateDocumentName = TRANSLATE(
    'views.documentation.failed_to_update_document_name'
  );
  documentationDocumentDeleted = TRANSLATE(
    'views.documentation.document_deleted'
  );
  documentationDocumentNotDeleted = TRANSLATE(
    'views.documentation.document_not_deleted'
  );

  planManagementPlanManagement = TRANSLATE(
    'views.plan_management.plan_management'
  );

  planManagementCompany = TRANSLATE('views.plan_management.company');
  planManagementPlanTitle = TRANSLATE('views.plan_management.plan_title');
  planManagementPlanId = TRANSLATE('views.plan_management.plan_id');
  planManagementResidence = TRANSLATE('views.plan_management.residence');
  planManagementEdit = TRANSLATE('views.plan_management.edit');
  planManagementDestroy = TRANSLATE('views.plan_management.destroy');
  planManagementCreateANewPlan = TRANSLATE(
    'views.plan_management.create_a_new_plan'
  );
  planManagementCurrency = TRANSLATE('views.plan_management.currency');
  planManagementOptions = TRANSLATE('views.plan_management.options');
  planManagementSaveChanges = TRANSLATE('views.plan_management.save_changes');
  planManagementSubmit = TRANSLATE('views.plan_management.submit');
  planManagementOf = TRANSLATE('views.plan_management.of');
  planManagementEditCountryOfResidence = TRANSLATE(
    'views.plan_management.edit_country_of_residence'
  );
  planManagementUpdateCountryOfResidence = TRANSLATE(
    'views.plan_management.update_country_of_residence'
  );
  planManagementYouNeedToSelectCountriesBeforeUpdating = TRANSLATE(
    'views.plan_management.you_need_to_select_countries_before_updating'
  );
  planManagementListWithCountriesOfResidenceUpdated = TRANSLATE(
    'views.plan_management.list_with_countries_of_residence_updated'
  );
  planManagementSuccessPlanCreated = TRANSLATE(
    'views.plan_management.success_plan_created'
  );
  planManagementPlanUpdated = TRANSLATE('views.plan_management.plan_updated');
  planManagementPlanSuccesfullyDeleted = TRANSLATE(
    'views.plan_management.plan_succesfully_deleted'
  );
  planManagementEditPlan = TRANSLATE('views.plan_management.edit_plan');
  planManagementErrorOcccuredWhileGettingCurrencies = TRANSLATE(
    'views.plan_management.error_occcured_while_getting_currencies'
  );
  planManagementEmailSentSuccess = TRANSLATE(
    'views.plan_management.email_sent_success'
  );
  planManagementPDFComparisonCreatedSuccess = TRANSLATE(
    'views.plan_management.pdf_comparison_created_success'
  );
  planManagementCannotGetCurrencies = TRANSLATE(
    'views.plan_management.cannot_get_currencies'
  );
  planManagementCannotGetPlans = TRANSLATE(
    'views.plan_management.cannot_get_plans'
  );
  planManagementListWithCountriesOfResidence = TRANSLATE(
    'views.plan_management.list_with_countries_of_residence'
  );
  planManagementInputRequiredError = TRANSLATE(
    'views.plan_management.input_required_error'
  );
  planManagementNoPlanSelected = TRANSLATE(
    'views.plan_management.no_plan_selected'
  );
  planManagementToggleDisable = TRANSLATE(
    'views.plan_management.toggleDisable'
  );
  planManagementToggleEnable = TRANSLATE('views.plan_management.toggleEnable');

  companiesAllCompanies = TRANSLATE('views.companies.all_companies');
  companiesName = TRANSLATE('views.companies.name');
  companiesDescription = TRANSLATE('views.companies.description');
  companiesMore = TRANSLATE('views.companies.more');
  companiesDestroy = TRANSLATE('views.companies.destroy');
  companiesCompanyDetails = TRANSLATE('views.companies.company_details');
  companiesCompanyId = TRANSLATE('views.companies.company_id');
  companiesCompanyName = TRANSLATE('views.companies.company_name');
  companiesCompanyDescription = TRANSLATE(
    'views.companies.company_description'
  );
  companiesCreatedBy = TRANSLATE('views.companies.created_by');
  companiesCreatedAt = TRANSLATE('views.companies.created_at');
  companiesLogo = TRANSLATE('views.companies.logo');
  companiesSaveChanges = TRANSLATE('views.companies.save_changes');
  companiesChooseFile = TRANSLATE('views.companies.choose_file');
  companiesNoFileChosen = TRANSLATE('views.companies.no_file_chosen');
  companiesUpload = TRANSLATE('views.companies.upload');
  companiesUploadLogo = TRANSLATE('views.companies.upload_logo');
  companiesSearchUser = TRANSLATE('views.companies.search_user');
  companiesSearchCompany = TRANSLATE('views.companies.search_company');
  companiesCompany = TRANSLATE('views.companies.company');
  companiesOf = TRANSLATE('views.companies.of');
  companiesNewCompany = TRANSLATE('views.companies.new_company');
  companiesSuccessCompanyAdded = TRANSLATE(
    'views.companies.success_company_added'
  );
  companiesCompanyUpdated = TRANSLATE('views.companies.company_updated');
  companiesUpdatedAt = TRANSLATE('views.companies.updated_at');
  companiesSubmit = TRANSLATE('views.companies.submit');
  companiesFailedToAdd = TRANSLATE('views.companies.failed_to_add');
  companiesFailedToUploadFile = TRANSLATE(
    'views.companies.failed_to_upload_file'
  );
  companiesLogoUploaded = TRANSLATE('views.companies.logo_uploaded');
  companiesFailedToUpdateCompany = TRANSLATE(
    'views.companies.failed_to_update_company'
  );
  companiesCompanyAdded = TRANSLATE('views.companies.company_added');
  companiesToggleDisable = TRANSLATE('views.companies.toggleDisable');
  companiesToggleEnable = TRANSLATE('views.companies.toggleEnable');
  companiesDeleted = TRANSLATE('views.companies.company_deleted');


  statesFrenchiseNone = TRANSLATE('views.states_frenchise.none');
  statesFrenchiseDeductible = TRANSLATE('views.states_frenchise.deductible');
  statesFrenchiseExcess = TRANSLATE('views.states_frenchise.excess');
  statesFrenchiseFrenchise = TRANSLATE('views.states_frenchise.franchise');

}
