import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { VocabularyService } from 'src/shared/services/vocabulary.service';
import { IApiFailed } from '@app/navShell/nav.model';
import { AuthGuard } from '@shared/services/auth.guard';

function flatten<T>(array: T[][]): T[] {
  return array.reduce((acc, curr) => [...acc, ...curr], []);
}

function formatErrors(errors: string[]): string {
  return errors
    .reduce((acc: string, curr: string) => `${acc}, ${curr.toLowerCase()}`, '')
    .substr(1);
}

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
  constructor(
    private _auth: AuthGuard,
    private _router: Router,
    private _notificationService: NotificationService,
    private _vb: VocabularyService
  ) {}

  mapErrorResponse(response: any): IApiFailed {
    let result: IApiFailed = {
      statusCode: null,
      message: null,
      name: null,
      code: null,
      status: null,
      stack: null
    };

    if (response && response.error && response.error.error) {
      result = {
        statusCode: response.error.error.statusCode,
        message: response.error.error.message,
        name: response.error.error.name,
        code: response.error.error.code,
        status: response.error.error.status,
        stack: response.error.error.stack
      };
    }

    return result;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(response => {
        const erData: IApiFailed = this.mapErrorResponse(response);

        if (response instanceof HttpErrorResponse && response.status === 401) {
          this._notificationService.showError(erData.message);
          this._auth.cleanState();
          this._router.navigate(['/']);
        } else if (
          response instanceof HttpErrorResponse &&
          response.status === 422
        ) {
          const { error } = response;
          const messages = Object.values(
            error.error.details.messages
          ) as string[][];

          this._notificationService.showError(
            formatErrors(flatten(messages)),
            true
          );
        } else {
          this._notificationService.showError(erData.message);
        }

        return throwError(erData);
      })
    );
  }
}
