import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '@shared/components/snackbar/snackbar.component';
import { of } from 'rxjs';

export const enum NotificationType {
  Error = 'error',
  Info = 'info',
  Success = 'success'
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  showSuccess(message: string, translate?: boolean) {
    this.openSnack(message, NotificationType.Success, translate);
  }

  showInfo(message: string, translate?: boolean) {
    this.openSnack(message, NotificationType.Info, translate);
  }

  showError(message: string, translate?: boolean) {
    this.openSnack(message, NotificationType.Error, translate);
  }

  openSnack(msg: string, type: NotificationType, translate?: boolean) {
    const msg$ = translate ? this.translate.get(msg) : of(msg);
    msg$.subscribe(message => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: {message, type},
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    });
  }
}
